import React from 'react'

import customor from '../images/kunden.png'
import { OurCustomorstext } from './Data'


const OurCustomors = () => {
  return (
    <div id='Customors' className='Our-Customors'>
        <div className='Our-Customors-img'>
            <img src={customor}></img>
        </div>

        <div className='Our-Customors-text'>

            <div className='Our-Customors-headline'>
                <h2>DAS SAGEN</h2>
                <h2>UNSERE KUNDEN</h2>
            </div>
            
            {OurCustomorstext.map((text)=>{
                return(
                    <div key={text.id} className='the-costomor'>
                        <h3>{text.headline}</h3>
                        <p>{text.text}</p>
                    </div>
                )
            })}
        </div>
    </div>

  )
}

export default OurCustomors