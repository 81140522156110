import React from 'react'
import '../styles/main.css'

const Colorbar = () => {
  return (
    <div className='color-bar'>
        
    </div>
  )
}

export default Colorbar