import React from 'react'
import { AboutText } from './Data'
import cloud from '../images/cloud.png'

const About = () => {
  return (
   <div id='about' className='About'>
    {AboutText.map((text) =>{
      return(
        <div key={text.id} className='About-text'>
          <h2>{text.headline}</h2>
          <p>{text.text1}</p>
          <p>{text.text2}</p>
        </div>
      )
    })}
    <div className='About-img'>
    <img src={cloud}></img>
    </div>
   </div>
  )
}

export default About