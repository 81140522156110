import React from 'react'
import { OurTeamtext } from './Data'

import sebastian from '../images/Sebastian.png'
import Felix from '../images/Felix.png'
import Alexander from '../images/Alexander.png'



const Ourteam = () => {
  return (
    <div className='Our-Team'>
      <h2>Kontakt</h2>


    <div className='Team-Members'>

       

        <div className='Team-Member'>
            <img src={Felix}></img>

            <div className='Team-Member-description'>
            <p className='bold'>Felix Merz</p>
            <p>CEO & Sales</p>
          </div>
        </div>

        <div className='Team-Member'>
            <img src={sebastian}></img>

            <div className='Team-Member-description'>
            <p className='bold'>Sebastian Mucha</p>
            <p>Legal, Regulatory<br></br> Framework</p>
          </div>
        </div>

        <div className='Team-Member'>
            <img src={Alexander}></img>

            <div className='Team-Member-description'>
            <p className='bold'>Alexander Much</p>
            <p>Software Entwicklung</p>
          </div>
        </div>
    
    </div>

      

      <div className='Team-text'>
        <p className='bold'>
        Kontaktieren Sie unser Team 
        </p>
        <br></br>
        <p> 
          Arbeiten Sie mit Climate Proof zusammen, um Ihr Unternehmen zum Vorreiter im Klimaschutz zu machen und von einer umfassenden, effizienten und gesetzeskonformen Softwarelösung zu profitieren, die Nachhaltigkeitsberichterstattung, CO2-Emissionsberechnung und Datenanalyse in einem benutzerfreundlichen Tool vereint. Damit schaffen Sie bereits heute einen Wettbewerbsvorteil für Ihr Unternehmen, indem Sie zukünftig Verpflichtungen vor Ihrer Konkurrenz erfüllen. 
        </p>
      </div>
      
    </div>
  )
}

export default Ourteam
