import Colorbar from "./componets/Colorbar";
import Navbar from "./componets/Navbar";

import Main from "./componets/Main";
import About from "./componets/About";
import OurService from "./componets/OurService";
import Why from "./componets/Why";
import OurCustomors from "./componets/OurCustomors";
import Contact from "./componets/Contact";
import Ourteam from "./componets/Ourteam";
import Footer from "./componets/Footer";

function App() {
  return (
    <>
      <Colorbar></Colorbar>
      <Navbar></Navbar>
      <Main></Main>
      <About></About>
      <OurService></OurService>
      <Colorbar></Colorbar>
      <Why></Why>
      <OurCustomors></OurCustomors>
      <Contact></Contact>
      <Ourteam></Ourteam>
      <Colorbar></Colorbar>
      <Footer></Footer>
    </>
    
  );
}

export default App;
