import React from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import { useRef } from 'react'
import logo from '../images/logo.svg'

import '../styles/main.css'

const Navbar = () => {

  const navRef = useRef()

  const showNavbar = () => {
    navRef.current.classList.toggle('responsive_nav')
  }

  return (
    <header>
        <img src={logo} className='header-logo'></img>
        
        <nav ref={navRef}>
            <a onClick={showNavbar} href='#about'>About</a>
            <a onClick={showNavbar} href='#service'>Service</a>
            <a onClick={showNavbar} href='#contact'>Kontakt</a>
            <button className='nav-btn nav-close-btn' onClick={showNavbar}>
              <FaTimes></FaTimes>
            </button>
        </nav>

        <button className='nav-btn' onClick={showNavbar}>
          <FaBars></FaBars>
        </button>
    </header>
  )
}

export default Navbar