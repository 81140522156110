import React from 'react'

const openMail = () =>{
  window.open('mailto:email@example.com?subject=Subject&body=Body%20goes%20here')
}

const Contact = () => {
  return (
    <div id="contact" className='Contact'>
        <h2>VEREINBAREN SIE JETZT IHR</h2>
        <h2>BERATUNGSGESPRÄCH</h2>
        <a href="mailto:info@climate-proof.net?subject=Kontaktaufnahme&body=Liebes%20Climate%20Proof%20Team%2C%0A%0Aich%20habe%20Interesse%20mehr%20%C3%BCber%20die%20Vorteile%20der%20Climate%20Proof%20L%C3%B6sung%20zu%20erfahren%20und%20bitte%20um%20Kontaktaufnahme.%0A%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen%0ANAME%0A%0A%0AP.S.%3A%20Wenn%20Sie%20uns%20eine%20Telefonnummer%20mitteilen%2C%20kontaktieren%20wir%20Sie%20gerne%20auch%20telefonisch.">KONTAKT</a>
    </div>
  )
}

export default Contact