import React from 'react'
import '../styles/main.css'

const main = () => {
  return (
    <main className='main'>
        <div className='main-content'>
          <h1>CLIMATE PROOF</h1>
          <p>BEYOND CARBON</p>
        </div>
        
    </main>
  )
}

export default main