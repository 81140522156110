import React from 'react'
import Companyreport from '../images/Runde_Form_Automatisierter_Unternehmensbericht.svg' 
import { OurServices } from './Data'

let colorNumber = 0

const OurService = () => {
  return (
    <div id='service' className='Service-Section'>
      <div className='Our-service'>       
        <h2>UNSER SERVICE</h2>
      </div>

   <div className='Services'>
        {OurServices.map((service)=>{
          colorNumber = colorNumber + 1
          console.log(colorNumber)
            return(
                <div key={service.id} className='Service'>
                    <img src={Companyreport}></img>
                    <h3>{service.headline1}</h3>
                    <h3>{service.headline2}</h3>
                    <p>{service.text}</p>
                </div>
            )
        })}
   </div>

    </div>

  )
}

export default OurService