import React from 'react'
import { Whycontent } from './Data'

const Why = () => {
  return (
    <div className='Why'>
      <h2>WARUM CLIMATEPROOF ?</h2>
      <div className='Why-content'>
        {Whycontent.map((content)=>{
          return(
            <div key={content.id} className='onewhy'>
            <h3>{content.headline}</h3>
            <p>{content.text}</p>
          </div>
          )
        })}
      </div>
    </div>
  )
}

export default Why