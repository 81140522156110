export const AboutText = [
    {
        id:1,
        headline: "ÜBER CLIMATE PROOF",
        text1:"Möchten Sie Ihr Unternehmen zum Vorreiter in Sachen Klimaschutz machen? Suchen Sie eine effiziente und gesetzeskonforme Lösung, um die Anforderungen der ab 2024 verpflichtenden EU-Richtlinie 2022/2464 zur Nachhaltigkeitsberichtserstattung zu erfüllen, die sich auf die gesamte Wertschöpfungskette erweitern lässt? Dann sind Sie bei uns genau richtig!  ",
        text2:"Climate Proof bietet Ihnen eine innovative Software, die Ihr Unternehmen von der Nachhaltigkeitsberichterstattung bis hin zur Ermittlung der CO2-Emissionen entlang der gesamten Wertschöpfungskette unterstützt. Unser digitales Tool revolutioniert die Art und Weise, wie Sie Nachhaltigkeit in Ihrem Unternehmen abbilden. Schluss mit komplexen Excel-Tabellen und aufwendigen manuellen Prozessen – mit Climate Proof haben Sie alles in einem einzigen, benutzerfreundlichen Programm."
    }
]

export const OurServices = [
    {
        id:1,
        headline1:'AUTOMATISIERTER ',
        headline2:'UNTERNEHMENSBERICHT',
        text:'Automatisierter Bericht mit allen wichtigen Kennzahlen der relevanten Richtlinien.'
    },

    {
        id:2,
        headline1:'CO2 REDUKTIONS',
        headline2:'ANALYSE',
        text:'Untersuchung von Maßnahmen zur Verringerung von Treibhausgas Emissionen.'
    },

    {
        id:3,
        headline1:'CO2 MARKETING',
        text:'Vermarkten Sie Ihre nachgewiesenen Emmissions-Reduktionen gewinnbringend.'
    }
]

export const Whycontent = [
    {
        id:1,
        headline:'AUTOMATISIERUNG',
        text:'Climate Proof automatisiert die Nachhaltigkeitsberichterstattung gemäß den Vorgaben der EU-Richtlinie 2022/2464, die ab 2024 verpflichtend in Kraft tritt. '
    },
    {
        id:2,
        headline:'DATENSCHUTZ',
        text:'Unsere durchgängig digitale Software verschlüsselt Ihre Unternehmensdaten mithilfe modernster Kryptographie. '
    },
    {
        id:3,
        headline:'ANALYSEN',
        text:'Erhalten Sie mit Climate Proof per Knopfdruck Unternehmensberichte, CO2-Einsparpotenziale und Benchmarkanalysen. '
    }
]

export const OurCustomorstext = [
    {
        id:1,
        headline:'Gerda Frank:',
        text:'„Dank der Climate Proof Software konnten wir unser KIT Projekt "Prio Optics" für den EXIST-Antrag sehr gut an den Nachhaltigkeitszielen der Bundesregierung ausrichten und eine ESG-konforme Nachhaltigkeitsstrategie entwickeln. Das Ganze in nur 2 Wochen, ohne Vorkenntnisse der komplexen Regulatorik.“ '
    },
    
    {
        id:3,
        headline:'Philipp Pfeifer ',
        text:'„Die Software von Climate Proof kommt zum richtigen Zeitpunkt: Die Nachhaltigkeitsberichtserstattung befindet sich im Umbruch und die Lösung von Climate Proof schafft hier ein zentrales Tool, das Firmen befähigt, sich in der komplexen Welt der Regulatorik zurechtzufinden.“ '
    }
]

export const OurTeamtext = [
    {
        id:1,
        name:'Sebastian',
        position:'Founder and CEO',
        
    },
    {
        id:2,
        name:'Felix Merz',
        position:'Founder and CEO',
        picture:'sebastian'
    },
    {
        id:3,
        name:'Felix Merz',
        position:'Founder and CEO',
        picture:'sebastian'
    }


]
